(function($) {﻿

	"use strict";

	var d = [],
		﻿﻿doc = $(document),
		body = $('body'),
		﻿﻿ua = navigator.userAgent.toLowerCase(),
		﻿﻿wndw = $(window),
		﻿﻿w = [];

	$.modal = function(data, options) {﻿﻿
		return $.modal.impl.init(data, options);﻿
	};

	$.modal.close = function() {﻿﻿
		$.modal.impl.close();﻿
	};

	$.fn.modal = function(options) {﻿﻿
		return $.modal.impl.init(this, options);﻿
	};

	$.modal.defaults = {﻿﻿
		bodyClass: 'modal-on',
		modalClass: '',
		appendTo: 'body',
		close: true,
		closeBehavior: 'modal-close',
		﻿﻿escClose: true,
		overlayClose: true,
		﻿﻿onOpen: null,
		﻿﻿onShow: null,
		﻿﻿onClose: null﻿,
		addButtons: false,
		buttons: {
			ok: 'Ok',
			cancel: 'Cancel'
		}
	};

	$.modal.impl = {﻿﻿

		d: {},
		﻿﻿
		﻿﻿init: function(data, options) {﻿﻿﻿
			var s = this;

			if (s.d.data) {﻿﻿﻿﻿
				return false;﻿﻿﻿
			}

			s.o = $.extend({}, $.modal.defaults, options);

			if (typeof data === 'object') {﻿﻿﻿﻿
				data = data instanceof $ ? data : $(data);﻿﻿﻿﻿
			}﻿﻿﻿
			else if (typeof data === 'string' || typeof data === 'number') {﻿﻿﻿﻿
				data = $('<div>').html(data).contents();﻿﻿﻿
			}﻿﻿﻿
			else {﻿﻿﻿﻿
				alert('Little Red Modal Error: Unsupported data type: ' + typeof data);﻿﻿﻿﻿
				return s;﻿﻿﻿
			}

			s.create(data);﻿﻿﻿
			data = null;

			s.open();

			if ($.isFunction(s.o.onShow)) {﻿﻿﻿﻿
				s.o.onShow.apply(s, [s.d]);﻿﻿﻿
			}

			return s;
		},
		﻿﻿create: function(data) {﻿﻿﻿
			var s = this;

			s.d.modal = $("<div>").addClass('modal ' + s.o.modalClass).appendTo(s.o.appendTo);
			s.d.overlay = $("<div>").addClass('modal-overlay').appendTo(s.d.modal);
			s.d.container = $("<div>").addClass('modal-container').appendTo(s.d.modal);
			s.d.wrap = $("<div>").addClass('modal-wrap').appendTo(s.d.container);
			s.d.close = ! s.o.close || $("<span>").addClass('modal-btnClose').attr('data-behavior', 'modal-close').appendTo(s.d.wrap);
			s.d.title = s.o.title ? $("<h1>", { html: s.o.title }).addClass('modal-heading').appendTo(s.d.wrap) : false;
			s.d.body = $("<div>").addClass('modal-body').appendTo(s.d.wrap);
			s.d.data = data.appendTo(s.d.body);
			data = null;

			if (s.o.addButtons) {
				s.addButtons();
			}

		},

		addButtons: function() {
			var s = this;

			s.d.buttons = $("<div>").addClass('btns');

			if (s.o.buttons.ok !== undefined) {

				s.d.buttons.append(
					$("<button>", {
						text: typeof s.o.buttons.ok === 'object' ? s.o.buttons.ok.label : s.o.buttons.ok
					})
					.click(function(e) {
						if (typeof s.o.buttons.ok === 'object') {
							return s.o.buttons.ok.action.call(null, s);
						}

						e.preventDefault();﻿﻿﻿﻿
						s.close();﻿﻿﻿
					})
					.addClass('btn btn-primary')
					.addClass(s.o.buttons.ok.buttonClass || 'bg-green')
					.attr('data-behavior', 'btn-ok')
				);

			}

			if (s.o.buttons.cancel !== undefined) {

				s.d.buttons.append(
					$("<button>", {
						text: typeof s.o.buttons.cancel === 'object' ? s.o.buttons.cancel.label : s.o.buttons.cancel
					})
					.click(function(e) {
						if (typeof s.o.buttons.cancel === 'object') {
							return s.o.buttons.cancel.action.call(null, s);
						}

						e.preventDefault();﻿﻿﻿﻿
						s.close();﻿﻿﻿
					})
					.addClass('btn btn-secondary')
					.addClass(s.o.buttons.cancel.buttonClass || '')
				)
			}

			s.d.buttons.appendTo(s.d.body);
		},

		﻿﻿open: function() {﻿﻿﻿
			var s = this;
						﻿﻿﻿
			if ($.isFunction(s.o.onOpen)) {﻿				﻿﻿﻿﻿
				s.o.onOpen.apply(s, [s.d]);﻿﻿﻿
			}﻿﻿﻿

			s.d.modal.show();
			body.addClass(s.o.bodyClass);
			s.bindEvents();﻿﻿
			wndw.trigger('resize.lrmodal');
		},

		﻿﻿bindEvents: function() {﻿﻿﻿
			var s = this;

			$('[data-behavior=' + s.o.closeBehavior + ']').bind('click.lrmodal', function(e) {﻿﻿﻿﻿
				e.preventDefault();﻿﻿﻿﻿
				s.close();﻿﻿﻿
			});

			if (s.o.close && s.o.overlayClose) {﻿﻿﻿﻿
				s.d.overlay.bind('mousedown.lrmodal', function(e) {
					if (e.which == 1) {
						e.preventDefault();
						s.close();
					}
				});

				s.d.container.bind('mousedown.lrmodal', function(e) {﻿﻿﻿﻿﻿
					if (e.target === e.currentTarget && e.which == 1) {
						e.preventDefault();﻿﻿﻿﻿﻿
						s.close();﻿﻿﻿﻿
					}
				});﻿﻿﻿
			}

			doc.bind('keydown.lrmodal', function(e) {﻿﻿﻿﻿
				if ((s.o.close && s.o.escClose) && e.keyCode === 27) {
					﻿﻿﻿﻿﻿
					e.preventDefault();﻿﻿﻿﻿﻿
					s.close();﻿﻿﻿﻿
				}﻿﻿﻿
			});

			wndw.bind('resize.lrmodal orientationchange.lrmodal', function() {﻿﻿﻿﻿

				var $modal = s.d.wrap;

				if ($modal.outerHeight() >= wndw.height()) {
					body.addClass('modal-scroll');

					$modal.css({
						'margin-left': '',
						'margin-top': ''
					});
				} else {
					body.removeClass('modal-scroll');

					$modal.css({
						'margin-left': ( $modal.outerWidth() / 2 ) * -1,
						'margin-top': ( $modal.outerHeight() / 2 ) * -1
					});
				}
			});﻿﻿
		},
		﻿﻿unbindEvents: function() {﻿﻿﻿
			$('[data-behavior=' + this.o.closeBehavior + ']').unbind('click.lrmodal');﻿﻿﻿
			doc.unbind('keydown.lrmodal');﻿﻿﻿
			wndw.unbind('.lrmodal');﻿﻿﻿
			this.d.container.unbind('mousedown.lrmodal');﻿﻿
			this.d.overlay.unbind('mousedown.lrmodal');﻿﻿
		},
		﻿﻿close: function() {﻿﻿﻿
			var s = this;

			if (!s.d.data) {﻿﻿﻿﻿
				return false;﻿﻿﻿
			}

			s.unbindEvents();
			body.removeClass('modal-scroll');

			if ($.isFunction(s.o.onClose) && !s.occb) {﻿﻿﻿﻿ // set the onClose callback flag
				s.occb = true;
				s.o.onClose.apply(s, [s.d]);﻿﻿﻿
			}﻿﻿﻿
			else {﻿﻿﻿﻿ // if the data came from the DOM, put it back
				s.d.modal.remove();
				s.d = {};﻿﻿﻿
				body.removeClass(s.o.bodyClass);
			}﻿﻿
		}﻿


	};

	﻿
})( jQuery );
