(function() {
	jQuery(function() {

		Erm.associate = {
			set_offset: function(offset) {
				offset = offset || $("[data-behavior=associate-offset]:checked").val();

				$("[data-behavior=associate-rows] tr[data-row="+ offset +"]")
						.removeClass('row-notIncluded')
						.nextAll().removeClass('row-notIncluded').end()
						.prevAll().addClass('row-notIncluded');
			},
			set_disabled_column: function(e) {
				e = e || $("[data-behavior=associate-discard]");

				e.each(function() {

					// Column key values begin at 1, JS indexes at 0
					var column = $(this).val() - 1,
						on = $(this).prop('checked');

					$("[data-behavior=associate-table] tr").each(function() {
						$(this).find("td:eq("+column+"), th:eq("+column+")").toggleClass('column-notIncluded', !on);
					});

				});
			},
			set_enabled_column: function(e) {
				e = e || $("[data-behavior=selectize-populated-associate]");

				e.each(function() {
					var column = $(this).closest('th').index(),
						on = $(this)[0].selectize.isFull();

					$("[data-behavior=associate-table] tr").each(function() {
						$(this).find("td:eq("+column+"), th:eq("+column+")")
							.toggleClass('column-isAssociated', on)
							.toggleClass('column-notAssociated', ! on);
					});
				});
			},
			check_processing: function() {
				setTimeout(function() {
					$.ajax({
						url: $("[data-behavior=associate-pending]").data('action'),
						type: 'GET',
						dataType: 'json'
					})
					.done(function(x) {
						if ( ! x.is_processed) {
							return Erm.associate.check_processing();
						}

						location.reload();
					})
					.fail(function() {
						location.reload();
					});
				}, 1000);
			}
		};

		$(document).on("change", "[data-behavior=associate-offset]", function() {
			Erm.associate.set_offset($(this).val());
		});

		$(document).on("change", "[data-behavior=associate-discard]", function() {
			Erm.associate.set_disabled_column();
		});

		$(document).on("click", "[data-behavior=associate-ignore]", function() {
			var $row = $(this).closest('tr');

			$.modal('<p>Are you sure you want to remove this sale row?</p>', {
				title: 'Delete Sale Row',
				modalClass: 'modal-confirm',
				addButtons: true,
				buttons: {
					ok: {
						label: 'Delete Row',
						action: function(m) {
							$.ajax({
								url: Erm.url_route('/api/statements/:token', { token: Erm.inject.entity.token }),
								type: 'PUT',
								dataType: 'json',
								data: {
									import_id: $row.data('import-id')
								},
								beforeSend: function() {
									m.d.wrap.addClass('whirl');
								}
							})
							.done(function(x) {
								if (x.status) {

									$row.slideUp(200, function() {

										if ( ! $row.siblings('[data-import-id]').length) {

											$row.closest("[data-behavior=issue-modal]").fadeTo(200, 0, function() {

												if ($(this).siblings('[data-behavior=issue-modal]').length < 1) {
													$(this).closest("[data-behavior^=issue].issue-category").fadeTo(200, 0).slideUp(200);
												}

											}).slideUp(200, function() {
												$(this).remove();

												if ( ! $("[data-behavior=issue-modal]").length) {
													location.reload();
												}
											});

										} else {
											$(this).remove();
										}

									});

									m.close();
								}
							})
							.fail(function() {
								m.d.wrap.removeClass('whirl');
							})
						}
					},
					cancel: 'Cancel'
				}
			});
		});

		$(document).on("assigned", "[data-behavior=employee-role-assignment] [data-behavior=assign-to-contract]", function(e, x) {
			var employee_id = $(this).data('employee-id'),
				$form = $(this).closest('form[data-behavior=ajax]');
				$select = $form.find('[name=replacement]')[0].selectize;

			$select.setValue(x.employee.badge);
			$form.submit();
		});

		$(document).on("done", "[data-behavior=issue-modal] form[data-behavior=ajax]", function() {
			$(this).closest("[data-behavior=issue-modal]").slideUp(200, function() {
				if ( ! $(this).siblings('[data-behavior=issue-modal]').length) {
					$(this).closest('.issue-category').remove();
				} else {
					$(this).remove();
				}

				if ( ! $("[data-behavior=issue-modal]").length) {
					location.reload();
				}
			});
		});

		Erm.initialise_selectize_populated($("[data-behavior=selectize-populated-associate]"), {
			onChange: function() {
				Erm.associate.set_enabled_column(this.$input);
			}
		});

		if ($("[data-behavior=associate-pending]").length) {
			Erm.associate.check_processing();
		}

		$.each($("[data-behavior=load-employee]"), function() {
			var $el = $(this);
			$.ajax({
				url: $(this).data('action'),
				type: 'GET',
				dataType: 'json'
			})
			.done(function(x) {
				$el.append(
					Erm.templates['contracts/import/partials/errors/resolve/badge_number/attach'].render({
						employees: x.results.shift(),
						contract_id: Erm.inject.entity.contract_id,
						employees_str: JSON.stringify(x.results.shift())
					})
				);
			})
		});

		Erm.associate.set_offset();
		Erm.associate.set_disabled_column();
		Erm.associate.set_enabled_column();
	});
}).call(this);
