(function() {

	Highcharts.setOptions({
		chart: {
			animation: false,
			backgroundColor: 'none',
			plotBackgroundColor: 'none',
			style: {
				fontFamily: 'Roboto',
				fontSize: '14px'
			}
		},
		colors: ['#2ecc40','#ff851b', '#0074d9', '#ff4136', '#ffdc00', '#b10dc9', '#f012be'],
		title: false,
		plotOptions: {
			series: {
				animation: false,
				threshold: 0
			},
			area: {
				fillOpacity: 0.2,
				lineWidth: 3,
				marker: {
					radius: 2
				}
			},
			areaspline: {
				fillOpacity: 0.1,
				lineWidth: 2,
				marker: {
					radius: 3
				}
			},
			column: {
				stacking: 'normal'
			},
			pie: {
				dataLabels: {
					enabled: true,
					connectorColor: '#c5d6dd',
					connectorWidth: 1,
					softConnector: false,
					style: {
						fontSize: '14px',
						fontWeight: '400'
					}
				},
				tooltip: {
					pointFormatter: function () {
						return '<strong>' + this.y + '%</strong>';
					}
				}
			}
		},
		credits: { enabled: false },
		xAxis: {
			// type: 'datetime',
			dateTimeLabelFormats: {
				hour: "%H:%M ",
				day: "%e %b",
				week: "%e %b",
				month: "%b %Y"
			},
			lineColor: "#d4e0e6",
			gridLineWidth: 1,
			gridLineColor: "#d4e0e6",
			gridLineDashStyle: "Solid",
			labels: {
				y: 30,
				style: {
					color: '#344552',
					fontSize: '12px'
				}
			},
			title: {
				style: {
					color: '#91a0a7',
					fontWeight: '500'
				}
			}
		},
		yAxis: {
			gridLineColor: "#d4e0e6",
			gridLineDashStyle: "LongDash",
			min: 0,
			minRange: 1,
			minTickInterval: 5,
			labels: {
				x: -10,
				style: {
					color: '#344552',
					fontSize: '12px'
				}
			},
			title: {
				style: {
					color: '#91a0a7',
					fontWeight: '500'
				}
			}
		},
		loading: {
			labelStyle: {
				top: '50%'
			}
		},
		tooltip: {
			backgroundColor: 'rgba(255, 255, 255, 0.9)',
			borderRadius: 5,
			borderWidth: 2,
			crosshairs: [{
				width: 3,
				color: '#c5d6dd'
			}],
			followPointer: true,
			headerFormat: '<span style="font-size: 14px">{point.key}</span><br/>',
			shadow: false,
			style: {
				color: '#344552',
				fontSize: '14px'
			}
		},
		legend: {
			enabled: false
		},
		global: {
			useUTC: Erm.inject.date.config.utc,
			timezoneOffset: Erm.inject.date.config.offset
		}
	});

	$.extend(true, Erm, { charts: {
		data: {},
		initialise_charts: function() {
			Erm.charts.employee_retention();
			Erm.charts.contract_sales();
		},
		employee_retention: function(e, params) {
			e = e || $("[data-chart=employee-retention]");

			if ( ! e.length) return false;

			e.addClass('whirl');

			var chart = e.data('chart'),
				params = params || {},
				$form = $("form[data-chart-params="+chart+"]");

			if ($form.length) {
				params = $form.serialize();
			}

			$.getJSON(Erm.url_route('/chart/employees/:employee_id/retention', { employee_id: Erm.inject.entity.id }), params, function(json) {
				Erm.charts.render_employee_retention(e, json);
				Erm.charts.data[chart] = json;
			});
		},
		contract_sales: function(e, params) {
			e = e || $("[data-chart=contract-sales]");

			if ( ! e.length) return false;

			e.addClass('whirl');

			var chart = e.data('chart'),
				params = params || {},
				$form = $("form[data-chart-params="+chart+"]");

			if ($form.length) {
				params = $form.serialize();
			}

			$.getJSON(Erm.url_route('/chart/contracts/:contract_id/sales', { contract_id: Erm.inject.entity.id }), params, function(json) {
				Erm.charts.render_contract_sales(e, json);
			});
		},
		render_employee_retention: function(e, data) {
			e.removeClass('whirl');

			var stats = $.extend(true, {}, data, {
				period: {
					from: Erm.moment(data.period.from, 'YYYY-MM-DD').format(Erm.rome.default.inputFormat),
					to: Erm.moment(data.period.to, 'YYYY-MM-DD').format(Erm.rome.default.inputFormat)
				}
			});

			var template = Erm.templates['employees/charts/retention'].render(stats);

			e.empty().append(template).find("[data-chart=pie]").highcharts({
				chart: {
					type: 'pie'
				},
				series: [{
					dataLabels: {
						enabled: false
					},
					data: data.series
				}],
				tooltip: {
					crosshairs: false
				}
			});
		},
		get_chart_data: function(chartId) {
			if (chartId === undefined || ! chartId) return false;
			return $.extend({}, Erm.charts.data[chartId]) || {};
		},
		customise_employee_retention: function() {
			var chartId = $(this).data('chart-id'),
				data = Erm.charts.get_chart_data(chartId) || {};

			if (data) {
				$.extend(data, {
					period: {
						from: Erm.moment(data.period.from, 'YYYY-MM-DD').format(Erm.rome.default.inputFormat),
						to: Erm.moment(data.period.to, 'YYYY-MM-DD').format(Erm.rome.default.inputFormat)
					}
				});
			}

			$.modal(Erm.templates['employees/charts/retention_customise'].render(data), {
				title: 'Filter Stats',
				onOpen: function(e) {
					Erm.initialise_datepickers(e.data);
					Erm.initialise_form_validation(e.data.find('form'));

					Erm.initialise_selectize_search(e.data.find('[data-behavior=selectize-search-contracts]'), '/search/contracts', {
						valueField: 'id',
						labelField: 'name',
						searchField: ['name', 'identifier'],
						render: {
							option: function(item, escape) {
								return '<div class="group">' +
									'<h3 class="selectize-option-employee-name highlightable">'+escape(item.name)+'</h3>'+
									( item.identifier ? '<em class="selectize-option-employee-badgeNo highlightable">'+escape(item.identifier)+'</em> ' : '' ) +
								'</div>';
							}
						}
					});
				},
				addButtons: true,
				buttons: {
					ok: {
						label: 'Update',
						action: function(m) {

							var $form = m.d.body.find('form'),
								data = $form.serializeArray(),
								from = Erm.get_form_param(data, 'date_from'),
								to = Erm.get_form_param(data, 'date_to');

							if ( ! $form.valid()) return;

							data.push({
								name: 'period',
								value: Erm.moment(from, Erm.rome.default.inputFormat).format('YYYY-MM-DD') + '_' + Erm.moment(to, Erm.rome.default.inputFormat).format('YYYY-MM-DD')
							});

							Erm.charts.employee_retention(null, data);
							m.close();
						}
					},
					cancel: 'Cancel'
				}
			});
		},
		render_contract_sales: function(e, data)
		{
			e.removeClass('whirl');

			var template = Erm.templates['contracts/charts/sales'].render(data);

			e.empty().append(template).find("[data-chart]").highcharts({
				chart: {
					type: 'column'
				},
				plotOptions: {
					column: {
						borderWidth: 0,
						pointPadding: 0.1,
						groupPadding: 0
					}
				},
				series: data.series,
				xAxis: {
					categories: data.labels
				},
				yAxis: {
					title: {
						enabled: false
					}
				}
			});

		}
	}});

	Erm.charts.initialise_charts();

	$(document).on("click", "[data-behavior=customise-employee-retention]", Erm.charts.customise_employee_retention);

}).call(this);
