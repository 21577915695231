(function() {
	jQuery(function() {

		// Set up a search function
		Erm.search = function(query, e, opts) {
			var $form = e || $("form[data-behavior=master-search]"),
				route = $form.attr('action'),
				method = $form.find('[name=_method]').val() || $form.attr('method') || 'GET',
				query = query || $form.serializeObject().q,
				opts = $.extend({}, {
					pushState: true,
					changeInput: true
				}, opts);

			if (query == '') {
				return Erm.search_clear();
			}

			if (opts.changeInput) $form.find("[name=q]").val(query).trigger('keyup');

			// Cancel any running queries
			if (Erm.searching !== undefined) Erm.searching.abort();

			Erm.searching = $.ajax({
				url: route,
				type: method,
				dataType: 'json',
				data: $form.serialize(),
				beforeSend: function() {
					$form.append($("<div>").addClass('whirl'));
					$form.find('.error-desc').remove();
					$form.find('.field-error').removeClass('field-error');
				}
			})
			.done(function(x) {
				$form.trigger('done', x);

				if (opts.pushState) {
					// Push a base state when making a new search
					if ( ! $("[data-behavior=search-results]").length) {
						history.pushState({ is_search: 1, start: 1 });
					}

					history.pushState({ is_search: 1, query: query }, null, '?search=' + Erm.url_encode(query) );
					document.title = 'Search | ' + document.title.replace(/^Search \| /, '');
				}
			})
			.always(function() {
				$form.find('.whirl').remove();
			});
		};

		Erm.search_clear = function() {
			$("[data-behavior=search-results]").remove();
			$(".pageContent.hide").removeClass('hide');
			$("[name=q]").val('').trigger('keyup');
			history.pushState(null, null, location.pathname);
			document.title = document.title.replace(/^Search \| /, '');
		};

		// Master search behavior
		$(document).on("submit", "form[data-behavior=master-search]", function(e) {
			Erm.search();
			e.preventDefault();
		});

		$("form[data-behavior=master-search] input:text").typeWatch({
			callback: function() { Erm.search(); },
			wait: 150,
			captureLength: 0
		}).on('keyup', function(e) {
			if (e.which == 27) Erm.search_clear();
		});

		$(document).on("click", "[data-behavior=search-clear]", Erm.search_clear);

		// Perform actions when search has been completed
		$("[data-behavior=master-search]").on('done', function(e, d) {
			$("[data-behavior=search-results]").remove();

			var $results = $("<div>").addClass('pageContent').attr('data-behavior', 'search-results');


			if (d.total_results <= 0) {
				$results.append(Erm.templates['system/search/no-results'].render({ data: d }));
			} else {
				if (d.type == 'all') {
					$.each(d.results, function(type, results) {
						if (Erm.templates['system/search/'+type] !== undefined) {
							$results.append(Erm.templates['system/search/'+type].render({ data: results, query: Erm.url_encode(d.query), global: true }));
						}
					});
				} else {
					if ($.inArray('system/search/'+d.type, Erm.templates)) {
						$results.append(Erm.templates['system/search/'+d.type].render({ data: d }));
					}
				}
			}

			$(".pageContent").addClass('hide').after( $results );

		});

		// Automatically Submit search when there is a value
		$("[data-behavior=master-search] input[value!='']").closest('form').submit();

	});
}).call(this);
