(function() {

	window.Erm = {
		templates: templates,
		notifications: window.notifications,
		inject: window.inject,
		moment: rome.moment,
		xsrf: $('meta[name=xsrf]').attr('content'),
		rome: {
			default: {
				inputFormat: 'DD/MM/YYYY',
				time: false,
				weekStart: 1
			},
			dateTime: {
				time: true,
				inputFormat: 'DD/MM/YYYY h:mm a'
			},
			monthDay: {
				monthFormat: 'MMMM',
				inputFormat: 'DD/MM',
				styles: {
					'container': 'rd-container rd-monthdaypick'
				}
			},
			time: {
				time: true,
				date: false,
				inputFormat: 'h:mm a'
			},
			modal: {
				styles: {
					'container': 'rd-container rd-modal'
				}
			}
		},
		timeout: function(key, fn, delay) {
			Erm.timeouts = Erm.timeouts || {};
			Erm.timeouts[key] = setTimeout(fn, delay);
		},
		initialise: function()
		{
			if ( ! Erm.init) {
				accounting.settings.currency.symbol = "£";

				Erm.initialise_selectize();
				Erm.initialise_notifications();

				$.dynatableSetup({
					features: {
						paginate: false,
						search: false,
						recordCount: false,
						perPageSelect: false,
					},
					table: {
						defaultColumnIdStyle: 'underscore'
					}
				});

				Erm.initialise_dynatable();

				$.each(Erm.rome, function(t, o) {
					if (t == 'default') return;
					Erm.rome[t] = $.extend({}, Erm.rome.default, o);
				});

				Erm.initialise_datepickers();
				Erm.initialise_rangeslider();
				Erm.initialise_tooltipster();
				Erm.init = 1;
			}
		},
		initialise_selectize: function() {
			Erm.initialise_selectize_populated();
            Erm.initialise_selectize_creatable();
			Erm.initialise_selectize_taggable();
			Erm.initialise_selectize_search_employees();
		},
		initialise_selectize_populated: function(e, opts) {
			e = e || $(document).find('[data-behavior=selectize-populated]');

			if ( ! e.length) return false;

			e.each(function() {
				$(this).selectize($.extend({}, opts));
			});

			return e[0].selectize;
		},
        initialise_selectize_creatable: function(e, opts) {
            e = e || $(document).find('[data-behavior=selectize-creatable]');

            if ( ! e.length) return false;

            e.each(function() {
                $(this).selectize($.extend(true, {
                    create: function(input, escape) {
                        return {
                            value: '_new:' + input,
                            text: input
                        }
                    }
                }, opts));
            });

            return e[0].selectize;
        },
		initialise_selectize_taggable: function(e, opts) {
			e = e || $(document).find('[data-behavior=selectize-taggable]');

			if ( ! e.length) return false;

			e.each(function() {
				$(this).selectize($.extend(true, {
                    delimiter: ',',
                    maxItems: null,
                    hideSelected: true,
                    create: function(input, escape) {
                        return {
                            value: '_new:' + input,
                            text: input
                        }
                    }
				}, opts));
			});

			return e[0].selectize;
		},
		initialise_selectize_search: function(e, location, opts) {
			e = e || $(document).find('[data-behavior^=selectize-search]');

			if ( ! e.length) return false;

			var options = e.data('preload'),
				value = e.val();

			e.val('').selectize($.extend(true, {
				maxItems: 1,
				create: false,
				options: options || [],
				preload: true,
				load: function(query, callback) {
					var s = this;

					$.ajax({
						url: location || '/search',
						type: 'GET',
						data: { q: query },
						error: function() {
							callback();
						},
						success: function(res) {
							callback(res.results || res);
							s.setValue(value);
						}
					});
				}
			}, opts));

			return e[0].selectize;
		},
		initialise_selectize_search_employees: function(e, opts) {
			e = e || $(document).find('[data-behavior=selectize-search-employees]');
			return Erm.initialise_selectize_search(e, '/search/employees', $.extend(true, {
				valueField: e.data('value-key') || 'id',
				labelField: 'name',
				searchField: 'name',
				searchField: ['name', 'email', 'badge', 'telephone'],
				render: {
					option: function(item, escape) {
						return '<div class="custom-option selectize-option-employee"><div class="group">' +
							'<img class="selectize-option-employee-img img-circle" src="'+( item.avatar.original.url ? escape(item.avatar.original.url) : '')+'" alt="Employee Photo">'+
							'<h3 class="selectize-option-employee-name highlightable">'+escape(item.name)+'</h3>'+
							'<em class="selectize-option-employee-badgeNo highlightable">'+escape(item.badge)+'</em> '+
						'</div></div>';
					},
					item: function(item, escape) {
						return '<div>' +
							(item.name ? '<span class="name">' + escape(item.name) + '</span> ' : '') +
							(item.badge ? '<span class="badge">(' + escape(item.badge) + ')</span>' : '') +
						'</div>';
					},
				}
			}, opts));
		},
		initialise_datepickers: function(e) {
			e = e || $(document);

			$.each(e.find('[data-behavior^=datepicker]'), function() {
				var type = $(this).data('behavior').replace(/^datepicker-?/, '') || 'default';
				var opts = $(this).closest('.modal').length ? $.extend({}, Erm.rome[type], Erm.rome['modal']) : Erm.rome[type];

				var min = $(this).data('rome-min'),
					max = $(this).data('rome-max');

				$.extend(true, opts, {
					min: min ? Erm.moment(min) : '',
					max: max ? Erm.moment(max) : ''
				});

				rome(this, opts);
			});
		},
		destroy_datepickers: function(e) {
			e = e || $(document);
			$.each(e.find('[data-behavior^=datepicker][data-rome-id]'), function() {
				rome(this).destroy();
			});
		},
		initialise_notifications: function() {
			if ( ! Erm.notifications) return false;

			Erm.$notifications = Erm.$notifications || [];


			$.each(Erm.notifications, function(type, messages) {
				$.each(messages, function(i, message) {
					Erm.$notifications.push(Erm.templates['layouts/partials/notification'].render({ message: message, type: type }));
				});
			});

			setTimeout(Erm.render_notifications, 400);
		},
		render_notifications: function() {
			if ( ! Erm.$notifications.length) {
				Erm.start_notification_close();
				return false;
			}

			var $note = $( Erm.$notifications.shift() ).hide();

			if ( ! $('.notify').length) {
				var $container = $("<div>").addClass('notify').appendTo('body');
			}

			$note.appendTo('.notify').show().addClass('show');

			setTimeout(Erm.render_notifications, 200);
		},
		start_notification_close: function(delay) {
			delay = delay === undefined ? 5000 : delay;
			Erm.timeout('notification_close', Erm.close_notifications, delay);
		},
		stop_notification_close: function() {
			clearTimeout(Erm.timeouts['notification_close']);
		},
		close_notifications: function(e) {
			// Notification has been clicked close
			if (e !== undefined) {
				// Stop automatic closing of other notifications
				Erm.stop_notification_close();

				// Pause for this duration before automatically resuming automatic closing
				var pause = 5000;
			}

			e = e || $('.notify').children(':not([data-no-auto-close])').first();

			e.toggleClass('show remove');

			setTimeout(function() {
				e.slideUp(200, function() {
					$(this).remove();
					Erm.start_notification_close(pause || 100);
				});
			});
		},
		initialise_form_validation: function(e, opts) {
			opts = opts || {};

			e.validate($.extend({
				ignore: ':hidden:not([class~=selectized]),:hidden > .selectized, .selectize-control .selectize-input input',
				errorClass: 'error-desc',
				highlight: function(element, errorClass, validClass) {
					$(element).closest('.field').addClass('field-error');
				},
				unhighlight: function(element, errorClass, validClass) {
					$(element).closest('.field').removeClass('field-error');
				},
				errorPlacement: function(error, element) {
					error.appendTo( element.closest('.field') );
				},
				showErrors: function() {
					this.defaultShowErrors();
					$(window).trigger('resize');
				}
			}, opts));
		},
		initialise_rangeslider: function(e, opts) {
			e = e || $(document).find("[data-behavior=rangeslider]");

			if ( ! e.length) return false;

			e.each(function() {
				$(this).ionRangeSlider($.extend(true, {
					from: 25,
					min: 0,
					max: 100,
					postfix: "%"
				}, opts));
			});

			return $(e[0]).data("ionRangeSlider");
		},
		initialise_tooltipster: function(e, opts) {
			e = e || $(document).find("[data-tooltip]");

			if ( ! e.length) return false;

			e.each(function() {
				$(this).tooltipster($.extend(true, {
					delay: 100
				}, opts));
			});

			return $(e[0]).data("ionRangeSlider");
		},
		initialise_dynatable: function(e, opts) {
			e = e || $(document).find("[data-behavior=dynatable]");

			if ( ! e.length) return false;

			e.each(function() {
				var readers = {};

				$(this).find('[data-sort-type]').each(function() {
					var column = Erm.camelCase($(this).text()),
						type = $(this).data('sort-type');

					readers[column] = function(cell, record) {
						record[column + '_' + type] = $(cell).data(type);
						return $(cell).html();
					}

					$(this).attr("data-dynatable-sorts", column + '_' + type);
				});

				$(this).dynatable($.extend(true, {
					readers: readers
				}, opts));
			});

			return e[0];
		},
		microtime: function() {
			var now = new Date().getTime() / 1000;
			return now;
		},
		titleCase: function(str) {
			return str.replace(/(?:^|\s)\w/g, function(match) {
				return match.toUpperCase();
			});
		},
		camelCase: function(str) {
			str = Erm.titleCase(str).replace(/\s/g, '');
			return str.charAt(0).toLowerCase() + str.slice(1);
		},
		url_route: function(path, param) {
			$.each(param, function(key, val) {
				path = path.replace(':' + key, val);
			});
			return path.replace(/:\w+\/?/g, '');
		},
		url_encode: function(query) {
			return encodeURI( query ).replace(/%20/g, '+');
		},
		starts_with: function(haystack, needle) {
			return haystack.indexOf(needle) === 0;
		},
		get_form_param: function(serialized, param, def)
		{
			for (var i = serialized.length - 1; i >= 0; i--) {
				if (serialized[i]['name'] == param) return serialized[i]['value'];
			}

			return def;
		}
	};

	Erm.initialise();
}).call(this);
(function() {
	jQuery(document).ajaxSend(function(e, x, s) {
		if ( ! (/^(GET|HEAD|OPTIONS|TRACE)$/.test(s.type) ) ) {
			x.setRequestHeader("X-CSRF-TOKEN", Erm.xsrf);
		}
	});

	jQuery(document).ajaxError(function(x, e) {
		if (e.status == 500) {
			$.modal('<p>There was a problem processing this request.</p>', {
				modalClass: 'modal-confirm',
				title: 'Error',
				addButtons: true,
				buttons: {
					ok: 'Ok'
				}
			});
		}
	});
}).call(this);
