(function() {
	jQuery(function() {

		$(document).on("click", "[data-behavior=payslip-amend-transaction]", function(e) {
			var action = $(this).closest("[data-action]").data('action');

			$.ajax({
				url: action,
				type: 'GET',
				dataType: 'json',
				beforeSend: function() {
					$("[data-behavior=payslip-transactions]").addClass('whirl');
				}
			})
			.done(function(x) {
				var modal = $.modal(Erm.templates['payroll/partials/modal_amend_transaction'].render({
					action: action,
					transaction: x,
					amount: accounting.formatNumber(Math.abs(x.pivot.amount), 2)
				}), {
					title: 'Update Transaction'
				});

				modal.d.body.on('done', "[data-behavior=ajax]", function(e, x) {
					location.reload();
				});
			})
			.always(function() {
				$("[data-behavior=payslip-transactions]").removeClass('whirl');
			});
		});

		$(document).on("click", "[data-behavior=payslip-remove-transaction]", function(e) {
			var action = $(this).closest("[data-action]").data('action'),
				$el = $(this).closest("[data-transaction-id]");

			$.modal('<p>Are you sure you want to remove transaction?</p>', {
				modalClass: 'modal-confirm',
				title: 'Remove Transaction',
				addButtons: true,
				buttons: {
					ok: {
						label: 'Remove',
						action: function(m) {

							$.ajax({
								url: action,
								type: 'DELETE',
								dataType: 'json',
								beforeSend: function() {
									$("[data-behavior=payslip-transactions]").addClass('whirl');
								}
							})
							.done(function(x) {
								m.close();
								$el.slideUp(200, function() {
									$(this).remove();
								});
								location.reload();
							})
							.always(function() {
								$("[data-behavior=payslip-transactions]").removeClass('whirl');
							});

						}
					},
					cancel: 'Cancel'
				}
			});
		});

		$(document).on("change", "[data-behavior=payroll-status] [data-behavior=switch-animated]", function(e, active, old) {
			var state = active - 1,
				$s = $(this);

			$.ajax({
				url: Erm.url_route('/payslips/:id', { id: Erm.inject.entity.id }),
				type: 'PUT',
				dataType: 'json',
				data: {state: state},
				beforeSend: function() {
					$("[data-behavior=payroll-status]").addClass('whirl');
				}
			})
			.success(function(x) {
				$("[data-behavior=payslip]").attr('class', function(i, c) {
					return c.replace(/(^|\s)state-\S+/g, ' state-' + x.state_formatted);
				});

				if (x.final_bond != $("[data-final-bond]").data('final-bond')) {
					location.reload();
				}

				if ( ! x.is_payable) $("[data-behavior=payslip-paid]").addClass('hide');
			})
			.fail(function() {
				$s.children(':eq('+old+')').trigger('make-switch', false);
			})
			.always(function() {
				$("[data-behavior=payroll-status]").removeClass('whirl');
			});

			if (state == 0) {
				$("[data-show-when-amendable=0]").addClass('hide');
				$("[data-show-when-amendable=1]").removeClass('hide');
				return;
			}

			$("[data-show-when-amendable=0]").removeClass('hide');
			$("[data-show-when-amendable=1]").addClass('hide');
		});

		$(document).on("done", "[data-action=amend-pay_date]", function() {
			window.location.reload();
		});

		$(document).on("done-after-modal", "[data-action=remit-payslip]", function(e, x)
		{
			$(this).find('button').prop('disabled', true);

			$(this).before(
				$("<div>", {
					text: 'Remittance for this commission statement has been queued and will be sent shortly.'
				}).addClass('notice success')
			);
		});

        var states = [];
        var group = null;

        $(document).on("click", "[data-behavior=toggle-payslips] [data-state]", function() {
            var state = $(this).data('state');

            $(this).toggleClass('state-inactive');

            if (states.indexOf(state) >= 0) {
                _.pull(states, state);
            } else {
                states.push(state);
            }

            filterPayslips();
        });

		$(document).on("change", "#groupFilter", function() {
			group = $(this).val() || null;
            filterPayslips();
		});

        function filterPayslips() {
            var filtered = _.chain($("[data-behavior=payslips-list] a").toArray())
                            .each(function(item) {
                                $(item).hide();
                            })
                            .filter(function(item) {
                                return states.indexOf($(item).data('state')) < 0;
                            })
                            .filter(function(item) {
                                if (group === null) {
                                    return true;
                                }

                                var groups = JSON.parse($(item).attr('data-groups'));

                                if (! groups.length || groups.indexOf(group * 1) < 0) {
                                    return false;
                                }

                                return true;
                            })
                            .each(function(item) {
                                $(item).show();
                            });

            if (! filtered.value().length) {
                if ($("#roob").length) return;

                $("[data-behavior=payslips-list]").hide().after(
                    $("<div>", {
                        id: 'roob',
                        html: "<p>All commission statements have been filtered.</p>"
                    }).addClass("pageSection-empty")
                );
            } else {
                $("#roob").remove();
                $("[data-behavior=payslips-list]").show();
            }
        }

        Erm.initialise_selectize_populated($("#groupFilter"), {
            hideSelected: true,
            allowEmptyOption: true
        });

	});
}).call(this);
