(function() {
	jQuery(function() {

		var pusher = new Pusher('c8704dd06266e870bb4d', {
			encrypted: true,
			authEndpoint: '/pusher',
			auth: {
				headers: {
					'X-CSRF-TOKEN': Erm.xsrf
				}
			}
		});

		function updateMembers(members)
		{
			var $m = $("[data-behavior=presence-members]");

			if (members.count <= 1) {
				$m.remove();
				return;
			}

			var m = $.map(members.members, function(el) { return el.id != members.me.id ? el : null; });

			if ( ! $m.length) {
				$m = $("<div>").attr('data-behavior', 'presence-members');
				$("[data-behavior=notification-container]").prepend($m);
			}

			$m.replaceWith(Erm.templates['payroll/partials/payslip_warning'].render({ members: m, multiple: (m.length > 1) }));
		}

		if (Erm.inject && Erm.inject.presence) {
			var channel = pusher.subscribe('presence-' + Erm.inject.presence);

			channel.bind('pusher:subscription_succeeded', function(data) {
				updateMembers(channel.members);
			}).bind('pusher:member_removed', function(data) {
				updateMembers(channel.members);
			}).bind('pusher:member_added', function(data) {
				updateMembers(channel.members);
			});

		}


	});
}).call(this);
