(function() {
	Dropzone.autoDiscover = false;

	jQuery(function() {

		Erm.initialise_dropzone = function(e, opts) {
			e = e || $(document).find("[data-behavior^=upload]");

			if ( ! e.length) return false;

			e.each(function() {
				var $el = $(this);

				try {
					$el.dropzone($.extend(true, {
						clickable: '[data-behavior^=upload], [data-behavior^=upload] > *',
						url: $el.data('action'),
						headers: { "X-CSRF-TOKEN": Erm.xsrf },
						createImageThumbnails: false,
						init: function() {

							this.on('sending', function(file, d) {
								$el.addClass('whirl');
							});

							this.on('success', function(file, d) {
								if (d._redirect !== undefined) {
									window.location = d._redirect;
								}
							});

							this.on('complete', function() {
								$el.removeClass('whirl');
							});

							this.on('error', function(file, m, x) {

								var $message = $("<div>");

								m = typeof m == 'string' ? [m] : m;

								$.each(m, function(i, v) {
									$message.append($("<p>", { text: v }));
								});

								$.modal($message, {
									title: 'Whoops!',
									addButtons: true
								});

								this.removeFile(file);
							});

						}
					}, opts));
				} catch (e) { }
			});

			return e[0].dropzone;
		};

		Erm.initialise_dropzone($("[data-behavior=upload-statement]"), { acceptedFiles: '.csv', maxFiles: 1 });

		var avatar = Erm.initialise_dropzone($("[data-behavior=upload-photo]"), { acceptedFiles: 'image/*', maxFiles: 1 });

		if (avatar) {
			avatar.on('success', function(file, d) {
				this.removeFile(file);
				$(this.element).find("[data-behavior=avatar]").attr('src', d.thumbnail.url);
				$(this.element).find("[name=avatar]").val(d.token);
			});
		}

		Erm.initialise_dropzone();
	});
}).call(this);
