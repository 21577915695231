(function() {
	jQuery(function() {

		/*
		|--------------------------------------------------------------------------
		| Behaviors
		|--------------------------------------------------------------------------
		*/

		$(document).on("click", "[data-behavior=repeater-remove-item]", function() {
			$(this).closest('[data-behavior^=repeater-item]').remove();
		});

		$(document).on("click", "[data-behavior^=add-repeater-item][data-template]", function() {
			if (Erm.templates[$(this).data('template')] === undefined) return false;
			var repeater = Erm.templates[$(this).data('template')].render({ i: Erm.microtime });
			$(this).before(repeater);
		});

		$(document).on("click", "[data-behavior=add-repeater-item-telephone]", function() {
			var $repeater = $( Erm.templates['employees/partials/repeater_item_telephone'].render({ i: Erm.microtime }) );
			Erm.initialise_selectize_creatable($repeater.find('[data-behavior=selectize-creatable]'));
			$(this).before($repeater);
		});

		$(document).on("click", "[data-behavior=add-repeater-item-contact]", function() {
			var template = $(this).data('use-template') || 'employees/partials/repeater_item_contact';
			var $repeater = $( Erm.templates[template].render({ i: Erm.microtime }) );
			Erm.initialise_selectize_creatable($repeater.find('[data-behavior=selectize-creatable]'));
			$(this).before($repeater);
		});

		$(document).on("click", "[data-behavior=switch-animated] [data-behavior=toggle]", function() {
			$(this).trigger('make-switch');
		}).bind("make-switch", "[data-behavior=switch-animated] [data-behavior=toggle]", function(e, trigger) {
			var $e = $(e.target),
				$s = $e.closest('[data-behavior=switch-animated]'),
				active = $s.data('active'),
				idx = $e.index();

			if ($s.data('active') == idx) return;

			$s.data('active', idx).attr('data-active', idx);
			$e.addClass('on').siblings('[data-behavior=toggle]').removeClass('on');

			if (trigger !== false) $s.trigger('change', [idx, active]);
		});

		$(document).on("click", "[data-behavior=notification-closable] [data-behavior=notification-close]", function() {
			Erm.close_notifications($(this).closest('[data-behavior=notification-closable]'));
		});

		$(document).on("click", "[data-behavior=modal-close]", function() {
			$.modal.close();
		});

		$(document).on("submit", "form:not([data-behavior=ajax], [data-destructive])", function(e) {
			$(this).find('button').prop('disabled', true);
			$(this).find('button[type=submit], button:not([type])').addClass('btn-loading');
		});

		$(document).on("submit", "form[data-destructive]", function(e) {

			var $form = $(this);

			e.preventDefault();
			e.stopPropagation();

			var modal = $.modal(Erm.templates['common/partials/destructive'].render({ message: $form.find('[name=_confirmation]').val() }), {
				title: 'Warning!',
				modalClass: 'modal-confirm',
				addButtons: true,
				buttons: {
					ok: {
						label: 'Delete',
						action: function(m) {
							$form.removeAttr('data-destructive').submit();
							m.close();
						},
						buttonClass: 'bg-red'
					},
					cancel: 'Cancel'
				},
				onOpen: function() {
					var $btn = this.d.buttons.find("[data-behavior=btn-ok]").prop('disabled', true);

					this.d.body.find(":input[name=confirmation]").on("keyup", function() {
						$btn.prop('disabled', $(this).val() !== 'DELETE');
					});
				}
			});
		}).on("submit", "form[data-confirm]", function(e) {
			var $form = $(this);

			e.preventDefault();
			e.stopPropagation();

			var msg = $form.find('[name=_confirmation]').val() || "Would you like to proceed?";

			return $.modal("<div>"+msg+"</div>", {
				title: 'Are you sure?',
				modalClass: 'modal-confirm',
				addButtons: true,
				buttons: {
					ok: {
						label: 'Yes',
						action: function(m) {
							$form.removeAttr('data-confirm').submit();
							m.close();
						}
					},
					cancel: 'Cancel'
				}
			});
		});

		$(document).on("submit", "form[data-behavior=ajax]:not([data-confirm], [data-destructive])", function(e) {
			$(this).trigger("submit-ajax");
			e.preventDefault();
		}).on("submit-ajax", "form[data-behavior=ajax]", function(e) {

			var $form = $(this)
				route = Erm.url_route($form.attr('action'), $form.serializeObject()),
				method = $form.find('[name=_method]').val() || $form.attr('method');

			$.ajax({
				url: route,
				type: method,
				dataType: 'json',
				data: $form.serialize(),
				beforeSend: function() {
					if ($form.data('no-whirl') === undefined) $form.addClass('whirl');
					$form.find('.error-desc').remove();
					$form.find('.field-error').removeClass('field-error');
				}
			})
			.done(function(x) {
				$form.trigger('done', x);
				$.modal.close();
				$form.trigger('done-after-modal', x);

				if (x._redirect !== undefined) {
					window.location = x._redirect;
				}

				if (x._refresh !== undefined) {
					location.reload();
				}
			})
			.fail(function(x) {
				$form.trigger('fail', x);

				if (x.status === 422) {
					$.each(x.responseJSON, function(field, error) {

						if (Erm.starts_with(field, '_')) return;

						var $field = $form.find('[name='+field+']').closest('.field');

						if ( ! $field.length) {
							if ( ! $form.find('.error-desc.notice.error').length) {
								$form.prepend($("<div>").addClass('error-desc notice error'));
							}

							$field = $form.find('.error-desc.notice.error');
						}

						$field.addClass('field-error')

						switch (typeof error) {
							case 'object':
								$.each(error, function(i, v) {
									$field.append(
										$("<span>", { text: v }).addClass('error-desc')
									);
								});
								break;
							case 'string':
								$field.append(
									$("<span>", { text: error }).addClass('error-desc')
								);
								break;
						}
					});

					$(window).trigger('resize');
				}
			})
			.always(function() {
				$form.removeClass('whirl');
			});

		});

		var assignToContractOpts = {
			onOpen: function(e) {
				Erm.initialise_datepickers(e.data);

				var teams = Erm.initialise_selectize_creatable(e.data.find('[data-behavior=selectize-search-teams]'), {
					maxItems: 1
				});

				function setTeams(contract_id)
				{
					if ( ! contract_id) return false;

					teams.clearOptions();
					teams.disable();
					$.getJSON(Erm.url_route('/api/contracts/:id/teams', { id: contract_id }), function(json) {
						$.each(json, function(i, team) {
							teams.addOption({ value: team.id, text: team.name });
						});
						teams.refreshOptions(false);
						teams.enable();
					});
				}

				setTeams(e.data.find("[name=contract_id]").val());

				Erm.initialise_selectize_search_employees(e.data.find('[data-behavior=selectize-search-employees]'));

				Erm.initialise_selectize_search(e.data.find('[data-behavior=selectize-search-contracts]'), '/search/contracts', {
					valueField: 'id',
					labelField: 'name',
					searchField: ['name', 'identifier'],
					render: {
						option: function(item, escape) {
							return '<div class="group">' +
								'<h3 class="selectize-option-employee-name highlightable">'+escape(item.name)+'</h3>'+
								( item.identifier ? '<em class="selectize-option-employee-badgeNo highlightable">'+escape(item.identifier)+'</em> ' : '' ) +
							'</div>';
						}
					},
					onChange: function(contract_id) {
						setTeams(contract_id);
					}
				});

				Erm.initialise_selectize_search(e.data.find('[data-behavior=selectize-search-roles]'), '/api/contracts/roles', {
					valueField: 'id',
					labelField: 'name',
					searchField: ['name']
				});


				Erm.initialise_form_validation(e.data.find('form'));

				function setBondAmount(d) {
					var rate = parseFloat(e.data.find("[name=pay_rate]").val()) || 0;

					$("[data-behavior=pay-rate]").text( accounting.formatMoney(rate * ( d.from_percent / 100 )) );
				}

				var range = Erm.initialise_rangeslider(e.data.find("[data-behavior=rangeslider]"), {
					keyboard: true,
					keyboard_step: 1,
					onStart: setBondAmount,
					onChange: setBondAmount,
					onUpdate: setBondAmount
				});

				e.data.on("keyup", "[name=pay_rate]", function() {
					setBondAmount(range.result);
				});


				e.data.on('done', "[data-behavior=ajax]", function(event, x) {
					location.reload();
				});

				e.data.on('fail', "[data-behavior=ajax]", function(event, x) {
					x = x.responseJSON;

					if (x && x._role !== undefined) {
						window.location = x._role.routes.show;
					}

					if (x && x._step !== undefined) {
						e.data.find("[data-step="+x._step+"]").show().siblings("[data-step]").hide();
						e.data.find("form").append('<input type="hidden" name="step" value="'+x._step+'">');
					}
				});


			},
			onClose: function(e)
			{
				Erm.destroy_datepickers(e.data);
				$.modal.close();
			}
		};

		$(document).on("click", "[data-behavior=assign-contract-to-employee]", function() {
			var employee_id = $(this).data('employee-id') || Erm.inject.entity.id;

			var modal = $.modal(Erm.templates['employees/partials/modal_assign_to_contract'].render({ employee_id: employee_id, start_date: Erm.moment().format(Erm.rome.default.inputFormat) }), $.extend({}, assignToContractOpts, {
				title: 'Assign '+ ( Erm.inject.entity.name || 'this employee' ) +' to a contract',
			}));
		});

		$(document).on("click", "[data-behavior=assign-employee-to-contract]", function() {
			var modal = $.modal(Erm.templates['employees/partials/modal_assign_to_contract'].render({ contract_id: Erm.inject.entity.id, start_date: Erm.moment().format(Erm.rome.default.inputFormat) }), $.extend({}, assignToContractOpts, {
				title: 'Assign an employee to '+ ( Erm.inject.entity.name || 'this contract' ),
			}));
		});

		$(document).on("click", "[data-behavior=assign-to-contract]", function() {
			var employee_id = $(this).data('employee-id'),
				contract_id = $(this).data('contract-id'),
				$el = $(this);

			var modal = $.modal(Erm.templates['employees/partials/modal_assign_to_contract'].render({ employee_id: employee_id, contract_id: contract_id }), $.extend({}, assignToContractOpts, {
				title: 'Assign a new role'
			}));

			modal.d.body.on('done', "[data-behavior=ajax]", function(e, x) {
				$el.trigger('assigned', x);
			});

			modal.d.body.on('fail', "[data-behavior=ajax]", function(e, x) {
				x = x.responseJSON;
				modal.d.body.find("[data-behavior=role-overlap]").toggle( ( x && x._overlap !== undefined ) );
			});
		});

		$(document).on("click", "[data-behavior=add-transaction]", function() {
			var $el = $(this),
				entity = Erm.inject[$el.data('entity')] || Erm.inject.entity,
				attach = $el.data('attach') || 0,
				settings = {};

			var modal = $.modal(Erm.templates['employees/partials/modal_add_transaction'].render({ employee: entity, attach: attach }), {
				title: 'Add a Transaction',
				onOpen: function(e) {

					Erm.initialise_datepickers(e.data);

					var type = Erm.initialise_selectize_populated(e.data.find('[data-behavior=selectize-populated]'), {
						create: true,
						maxItems: 1,
						onChange: function(value) {
							if (settings[value] !== undefined) {
								$.each(settings[value], function(f, c) {
									var $e = e.data.find("[name="+f+"]"),
										state = $e.prop('checked');

									$e.prop('checked', c);

									if (state != c) {
										$e.trigger('change').trigger('toggleable');
									}
								});
							}
						}
					});

					type.disable();

					$.getJSON('/api/transactions/settings', function(json) {
						settings = json;
						type.enable();
					});

					e.data.on("change", "[name=repayable], [name=bond]", function() {
						var $repayable = e.data.find("[name=repayable]"),
							$bond = e.data.find("[name=bond]");

						if ($(this).is($repayable) && ! $repayable.prop('checked')) {
							$bond.prop('checked', false).trigger('change');
						}

						if ($(this).is($bond) && $bond.prop('checked')) {
							$repayable.prop('checked', true).trigger('change');
						}
					});

				}
			});

			modal.d.body.on('done', "[data-behavior=ajax]", function(e, x) {
				location.reload();
			});
		});

		$(document).on("click", "[data-behavior=amend-transaction]", function(e) {
			e.preventDefault();

			$.ajax({
				url: $(this).attr('href'),
				type: 'GET',
				dataType: 'json',
				beforeSend: function() {
					$("[data-behavior=transactions]").addClass('whirl');
				}
			})
			.done(function(x) {
				var modal = $.modal(Erm.templates['employees/partials/modal_amend_transaction'].render({
					transaction: x,
					amount: accounting.formatNumber(Math.abs(x.amount), 2),
					date: Erm.moment(x.date).format(Erm.rome.default.inputFormat),
					payslip: x.payslips ? x.payslips[x.payslips.length - 1] : null
				}), {
					title: 'Update Transaction',
					onOpen: function(e) {
						Erm.initialise_datepickers(e.data);
					}
				});

				modal.d.body.on('done', "[data-behavior=ajax]", function(e, x) {
					location.reload();
				});
			})
			.always(function() {
				$("[data-behavior=transactions]").removeClass('whirl');
			});
		});

		$(document).on("click", "[data-behavior=delete-transaction]", function(e) {
			var transaction_id = $(this).data('transaction-id');

			e.preventDefault();
			$.modal.close();

			$.modal('<p>Are you sure you want to delete this transaction?</p>', {
				title: 'Delete Transaction',
				modalClass: 'modal-confirm modal-confirm-remove',
				addButtons: true,
				buttons: {
					ok: {
						label: 'Delete Transaction',
						action: function(m) {
							$.ajax({
								url: Erm.url_route('/transactions/:id', { id: transaction_id }),
								type: 'DELETE',
								dataType: 'json',
							})
							.done(function() {
								location.reload();
							})
							.always(function() {
								m.close();
							});
						}
					},
					cancel: 'Cancel'
				}
			});

		});

		$(document).on("click", "[data-behavior=add-referral-fee]", function() {
			var $el = $(this);

			$el.addClass('btn-loading').prop('disabled', true);

			$.ajax({
				url: Erm.url_route('/api/employees/:employee_id', { employee_id: $el.data('referee-id') }),
				type: 'GET',
				dataType: 'json',
			})
			.done(function(x) {
				$el.removeClass('btn-loading').prop('disabled', false);

				var modal = $.modal(Erm.templates['employees/partials/modal_add_referral_fee'].render({ employee: Erm.inject.entity, referee: x }), {
					title: 'Add Referral Fee',
					onOpen: function(e) {
						Erm.initialise_datepickers(e.data);
					}
				});

				modal.d.body.on('done', "[data-behavior=ajax]", function(e, x) {
					location.reload();
				});

			});
		});

		$(document).on("click", "[data-behavior=avatar-remove]", function() {
			$("[data-behavior=avatar]").attr('src', $(this).data('fallback'));
			$("[name=avatar]").val('');
			$(this).remove();
		});

		$(document).on("change", "[data-format=number]", function() {
			$(this).val( accounting.formatNumber( $(this).val(), 2 ) );
		});

		$(document).on('click', ":text[readonly]", function() {
			$(this).select();
		});

		$(document).on("focus", "[data-editable]", function() {
			$(this).addClass('editing');
		}).on("blur", "[data-editable]", function() {
			$(this).removeClass('editing');
			$(this).trigger('save');
		}).on("save", "[data-editable]", function() {
			var content = $.trim($(this).text());

			$(this).toggleClass('empty', (content == ''));

			$(this).find(":input[name='"+$(this).data('editable')+"']").val(content);
			$(this).find("form").submit();
		});

		$(document).on("click", "[data-editable]:not([contenteditable])", function() {
			var $text = $(this),
				content = $.trim($text.text()),
				$form = $text.siblings('form');

			$text.hide();

			$form.show()
				.find(":input[name='"+$text.data('editable')+"']")
				.val(content)
				.focus().select()
				.on('blur', function() {
					$form.hide().submit();
					$text.show();
				}).on('keypress', function(e) {
					if (e.which == 13) {
						e.preventDefault();
						$(this).blur();
					}
				});
		});

		if ($("[data-key-navigation]").length) {
			Mousetrap.bind('left', function() {
				$("[data-key-navigation=prev]")[0].click();
			}).bind('right', function() {
				$("[data-key-navigation=next]")[0].click();
			}).bind('up', function() {
				$("[data-key-navigation=top]")[0].click();
			});
		}

		$(document).on("done", "[data-team-id]", function(e, x)
		{
			$(this).siblings("[data-editable]").text(x.name);
		});

	});
}).call(this);
