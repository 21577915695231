(function() {
	jQuery(function() {

		$.extend(true, Erm, {
			timeline: {
				data: [],
				initialise: function(e)
				{
					e = e || $("[data-behavior=role-history]");

					e.each(function() {
						var $el = $(this),
							role_id = $el.data('role-id');

						$.ajax({
							url: Erm.url_route('/api/roles/:role_id', { role_id: role_id }),
							type: 'GET',
							dataType: 'json',
							beforeSend: function() {
								$el.addClass('whirl');
							}
						})
						.done(function(json) {
							Erm.timeline.data = json;
							Erm.timeline.initialise_role_timeline($el, json.history);
						})
						.always(function() {
							$el.removeClass('whirl');
						});

					});

					Erm.timeline.initialise_role_creator();
				},
				initialise_role_timeline: function(e, history, opts) {
					if ( ! e.length) return false;

					try {
						var timeline = new vis.Timeline(e[0], Erm.timeline.format_historic_data(history), $.extend(true, {
							stack: false,
							zoomMin: 604800000,
							format: {
								majorLabels: {
									minute:     'ddd D MMMM YYYY',
									hour:       'ddd D MMMM YYYY'
								}
							},

						}, opts)).on('select', function(p) {
							if (p.items.length) {
								return Erm.timeline.edit_timeline_role(this, p.items[0]);
							}

							Erm.timeline.clear_role_editor();
						});

						Erm.timeline.set_timeline_zoom(timeline, history);

						Erm.timeline.vis = timeline;

						return timeline;
					} catch (e) { }

					return false;
				},
				set_timeline_zoom: function(timeline, history) {
					var start = history[0].from.date,
						last  = history[history.length - 1],
						end   = last.to ? last.to.date : last.from.date;

					timeline.setOptions({
						min: Erm.moment(start).subtract(2, 'days'),
						max: Erm.moment(end).add(7, 'days'),
					});
				},
				format_historic_data: function(history) {
					var data = new vis.DataSet();

					$.each(history, function(i, v) {
						data.add({
							id: v.id,
							content: Erm.templates['employees/partials/timeline_item'].render(v),
							start: v.from.date,
							end : v.to ? v.to.date : null,
							type: v.to ? 'range' : 'point'
						});
					});

					return data;
				},
				get_historic_role: function(role_id) {
					if ( ! Erm.timeline.data || ! Erm.timeline.data.history) return false;

					for (var i = Erm.timeline.data.history.length - 1; i >= 0; i--) {
						if (Erm.timeline.data.history[i]['id'] == role_id) return Erm.timeline.data.history[i];
					}

					return false;
				},
				clear_role_editor: function() {
					Erm.destroy_datepickers($("[data-behavior=role-editor]"));
					$("[data-behavior=role-editor]").empty();
					Erm.timeline.initialise_role_creator();
				},
				edit_timeline_role: function(timeline, role_id) {
					if ($("[data-behavior=role-editor] form[data-role-id="+role_id+"]").length) return false;

					var $el = $("[data-behavior=role-history]"),
						role = Erm.timeline.get_historic_role(role_id),
						data = {
							role: $.extend(true, role, {
								start_date: Erm.moment(role.from.date).format('DD/MM/YYYY'),
								end_date: role.to ? Erm.moment(role.to.date).format('DD/MM/YYYY') : null
							})
						},
						$editor = $(Erm.templates['employees/partials/timeline_amend_employee_role'].render(data));

					Erm.timeline.clear_role_editor();
					$("[data-behavior=role-editor]").append($editor);

					Erm.timeline.initialise_role_editor($editor);

					$editor.on("done", "form", function(e, json) {
						Erm.timeline.update_timeline(timeline, json.history, role_id);
					});

					$editor.on("click", "[data-behavior=cancel-editing]", function() {
						Erm.timeline.clear_role_editor();
						timeline.setSelection([]);
					});

					$("[data-behavior=add-role-point]").hide();
				},
				update_timeline: function(timeline, history, role_id) {
					Erm.timeline.clear_role_editor();
					Erm.timeline.data.history = history;

					timeline.setItems( Erm.timeline.format_historic_data(history) );
					Erm.timeline.set_timeline_zoom(timeline, history);

					if (role_id !== undefined) {
						// Attempt to focus the timeline on range only items
						try {
							timeline.focus(role_id);
						} catch (e) {
							timeline.fit();
						}
					}
				},
				initialise_role_editor: function($editor) {
					function setBondAmount(d) {
						var rate = parseFloat($editor.find("[name=pay_rate]").val()) || 0;

						$("[data-behavior=pay-rate]").text( accounting.formatMoney(rate * ( d.from_percent / 100 )) );
					}

					var range = Erm.initialise_rangeslider($editor.find("[data-behavior=rangeslider]"), {
						keyboard: true,
						keyboard_step: 1,
						from: $editor.find("[data-behavior=rangeslider]").val(),
						onStart: setBondAmount,
						onChange: setBondAmount,
						onUpdate: setBondAmount
					});

					Erm.initialise_datepickers($editor);

					$editor.on("keyup ", "[name=pay_rate]", function() {
						setBondAmount(range.result);
					});

					$editor.find("[name=pay_rate][value!='']").trigger('change');

					var teams = Erm.initialise_selectize_creatable($editor.find('[data-behavior=selectize-search-teams]'), {
						maxItems: 1
					});

					if (teams) {
						var team_val = teams.getValue();
							teams.disable();
							teams.clearOptions();

						$.extend(true, teams, {
							filler: function(options) {
								$.each(options, function(i, team) {
									teams.addOption({ value: team.id, text: team.name });
								});

								this.refreshOptions(false);
								this.enable();
								this.setValue(team_val);
							}
						});

						teams.load(function(callback) {
							var t = this;

							if (Erm.timeline.team_history !== undefined) {
								t.filler(Erm.timeline.team_history);
								return callback();
							}

							$.ajax({
								url: Erm.url_route('/api/contracts/:id/teams', { id: Erm.inject.entity.contract_id }),
								success: function(results) {
									Erm.timeline.team_history = results;
									t.filler(results);
									callback();
								},
								error: function() {
									callback();
								}
							})
						});

					}
				},
				initialise_role_creator: function() {
					if ($("[data-behavior=add-role-point]").length) {

						var data = {
							role: $.extend(true, Erm.inject.entity, {
								bond_percent: 25,
								start_date: Erm.moment().format('DD/MM/YYYY')
							})
						};

						var $creator = $(Erm.templates['employees/partials/timeline_add_employee_role'].render(data));
						$("[data-behavior=add-role-point]").empty().show().append($creator);
						Erm.timeline.initialise_role_editor($creator);

						$creator.on("done", "form", function(e, json) {
							delete Erm.timeline.team_history;
							Erm.timeline.update_timeline(Erm.timeline.vis, json.history, json.role.id);
						});
					}
				}
			}
		});

		Erm.timeline.initialise();
	});
}).call(this);
