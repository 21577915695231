(function() {
	jQuery(function() {
		$(document).on("click", "[data-tabs] [data-tab]", function(e) {
			e.preventDefault();

			var $container = $(this).closest("[data-tabs]"),
				tab = $(this).data('tab'),
				$content = $container.find("[data-tab-content="+tab+"]"),
				$tabs = $container.find("[data-tab]");

				$content.addClass('tabShow').siblings("[data-tab-content]").removeClass('tabShow');
				$tabs.removeClass('tabActive');
				$(this).addClass('tabActive');
		});

		$(document).on("click", "[data-toggle]", function(e) {
			$(this).trigger('toggleable');
		}).on("toggleable", "[data-toggle]", function(e) {
			var $toggleable = $("[data-toggleable='"+ $(this).data('toggle') +"']"),
				$el = $(this);

			$toggleable.toggle();
			$el.toggleClass('open', $toggleable.is('hidden'));

			if ($el.attr('data-toggle-self')) {
				$el.toggle();
			}
		});

		$(document).on("click", "[data-toggle-slide]", function(e) {
			var $toggleable = $("[data-toggleable='"+ $(this).data('toggle-slide') +"']"),
				$el = $(this);

			$toggleable.slideToggle(100, function() {
				$el.toggleClass('open', $toggleable.is(':visible'));
			});

		});

		$(document).on("click", "[data-filter]", function(e) {
			var query = $(this).data('filter'),
				elements = query.split(','),
				selector = '';

			$.each(elements, function(i, v) {
				 selector += "[data-filterable='"+v+"'], ";
			});

			if (query == 'filter-all') {
				$("[data-filterable]").show();
				history.replaceState(null, null, location.pathname);
				return;
			}

			$("[data-filterable]").hide();
			$(selector.replace(/, $/, '', selector)).show();
			history.replaceState({ is_filter: 1, query: query }, null, '?filter=' + Erm.url_encode( query ) );
		});

		$("[data-filter-trigger]").click();
	});
}).call(this);
