(function() {

	window.addEventListener("popstate", function(e, b) {
		if (e.state && e.state.is_search) {
			return e.state.start ? Erm.search_clear() : Erm.search(e.state.query, null, { pushState: false });
		}
	});

	jQuery(function() {

		/*
		|--------------------------------------------------------------------------
		| Menu Toggles
		|--------------------------------------------------------------------------
		*/

		/* Main Menu Toggle (Mobile) */
		$("#mainNavToggle").click(function() {
			$("#mainNav").fadeToggle(100, function() {
				if ($(this).is(':hidden')) {
					$(this).removeAttr('style');
				}
			});

			$("#mainNavToggle").toggleClass('on fa-bars fa-times');

		});

		/* mobileMenu Toggle (Mobile) */
		$(".btn-mobileNav").click(function() {
			$(".mobileNav .btns").fadeToggle(100, function() {
				if ($(this).is(':hidden')) {
					$(this).removeAttr('style');
				}
			});

			$(".mobileNav").toggleClass('mobileNav-open');
			$(".btn-mobileNav .fa").toggleClass('fa-ellipsis-v fa-times');

		});


		/*
		|--------------------------------------------------------------------------
		| Master Search
		|--------------------------------------------------------------------------
		*/

		var searchTimeout;

		// $("#masterSearch label").click(function() {
		// 	$("#masterSearch input").trigger('activate');
		// });

		$("#masterSearch input").focus(function() {
			$("#masterSearch input").trigger('activate');
			$("#masterSearch").addClass('focus');
			$("#masterSearch").removeClass('noFocus');
		}).keyup(function() {
			$("#masterSearch").toggleClass('hasText', $.trim($(this).val()) !== '');
		}).blur(function() {
			$(this).trigger('tryToReset');
			$("#masterSearch").removeClass('focus');
			$("#masterSearch").addClass('noFocus');
		}).bind('activate', function() {
			clearTimeout(searchTimeout);
			// $(this).show().siblings('label.masterSearch-placeholder').hide();
		}).bind('tryToReset', function() {
			if ($.trim($(this).val()) === '') {
				var $el = $(this);
				searchTimeout = setTimeout(function() {
					$el.trigger('reset');
				}, 500);
			}
		}).bind('reset', function() {
			// $(this).hide().siblings('label.masterSearch-placeholder').show();
		});

		/*
		|--------------------------------------------------------------------------
		| Selectize
		|--------------------------------------------------------------------------
		*/

		$('.si-tags').selectize({ create: true, plugins: ['remove_button'] });

		$('.si-single').selectize({
			maxItems: 1,
			valueField: 'title',
			labelField: 'title',
			searchField: ['title'],
			options: [
				{title: 'Lorem ipsum'},
				{title: 'Minima illum'},
				{title: 'Vitae odit earum accusantium'},
				{title: 'Sed temporibus fuga debitis'},
				{title: 'Voluptatum'}
			]
		});

		$('.si-custom').selectize({
			maxItems: 1,
			valueField: 'title',
			labelField: 'title',
			searchField: ['title'],
			options: [
				{title: 'Lorem ipsum', age: 5, added_by: 'John Smith'},
				{title: 'Minima illum', age: 30, added_by: 'Peter Blessing'},
				{title: 'Vitae odit earum accusantium', age: 10, added_by: 'Leslie Richardson'},
				{title: 'Sed temporibus fuga debitis', age: 22, added_by: 'Alan McCulloch'},
				{title: 'Voluptatum', age: 31, added_by: 'Barbera Wishart'}
			],
			render: {
				option: function(item, escape) {
					return '<div class="custom-option">' +
						'<h3 class="highlightable">'+escape(item.title)+'</h3>'+
						'<em>'+escape(item.added_by)+'</em> '+
						'(Age: <strong>'+escape(item.age)+'</strong>)'+
					'</div>';
				}
			}
		});

		$('.si-custom-employee').selectize({
			maxItems: 1,
			valueField: 'title',
			labelField: 'title',
			searchField: ['title', 'badge_no'],
			options: [
				{title: 'John Smith', badge_no: 3435, photo: 'http://placehold.it/200x200/0cf'},
				{title: 'Peter Blessing', badge_no: 4530, photo: 'http://placehold.it/200x200/f0c'},
				{title: 'Leslie Richardson', badge_no: 3510, photo: 'http://placehold.it/200x200/fa2'},
				{title: 'Alan McCulloch', badge_no: 4322, photo: 'http://placehold.it/200x200/f00'},
				{title: 'Barbera Wishart', badge_no: 3341, photo: 'http://placehold.it/200x200/fc0'}
			],
			render: {
				option: function(item, escape) {
					return '<div class="custom-option selectize-option-employee"><div class="group">' +
						'<img class="selectize-option-employee-img img-circle" src="'+escape(item.photo)+'" alt="Employee Photo">'+
						'<h3 class="selectize-option-employee-name highlightable">'+escape(item.title)+'</h3>'+
						'<em class="selectize-option-employee-badgeNo highlightable">'+escape(item.badge_no)+'</em> '+
					'</div></div>';
				}
			}
		});

	});
}).call(this);
